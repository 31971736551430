<template>
  <!-- <div
    class="h-screen flex w-full bg-primary vx-row no-gutter items-center justify-center"
    id="page-login"
  >-->
  <vx-card>
    <template slot="no-body">
      <img :src="backgroundUrl" @error="replaceImg" class="responsive" />
      <div class="card-overlay text-white flex flex-col justify-between">
        <div slot="no-body">
          <div class="vx-row no-gutter justify-center items-center">
            <div class="vx-col hidden lg:block lg:w-1/2" @click="0">
              <div id="login_data" class="vs-con-loading__container">
                <img
                  src="../../../assets/images/logo/mapa.png"
                  alt="login"
                  width="75%"
                  class="mx-auto"
                />
              </div>
            </div>
            <div class="vx-col sm:w-full md:w-full lg:w-1/2 d-theme-primary-bg">
              <div class="p-8">
                <div class="vx-card__title mb-8">
                  <h4 class="mb-4" style="color: white; font-size: 30px">
                    Login
                  </h4>
                  <p style="color: white; font-size: 16px">
                    Acesse o sistema
                    <b>SYS-ECONTROL</b> ©.
                  </p>
                </div>
                <form id="form-login">
                  <h5 style="color: white; font-size: 16px">E-mail</h5>
                  <vs-input
                    icon="icon icon-mail"
                    icon-pack="feather"
                    style="color: black"
                    autocomplete="off"
                    size="large"
                    name="email"
                    color="dark"
                    v-model="auth.email"
                    class="w-full no-icon-border"
                  />
                  <h5 class="mt-5" style="color: white; font-size: 16px">
                    Senha
                  </h5>
                  <vs-input
                    type="password"
                    icon="icon icon-lock"
                    icon-pack="feather"
                    style="color: black"
                    size="large"
                    autocomplete="off"
                    name="password"
                    color="dark"
                    v-model="auth.password"
                    class="w-full no-icon-border"
                  />
                  <div
                    style="font-size: 20px"
                    class="flex flex-wrap justify-between my-10"
                  >
                    <a
                      href="/recuperar-acesso/redefinir"
                      style="color: #c0564b; font-weight: 800"
                      >Esqueci minha senha</a
                    >
                  </div>
                  <vs-button
                    class
                    @click.prevent="login"
                    color="primary"
                    style="color: black; font-weight: 800"
                    >Entrar</vs-button
                  >
                </form>
              </div>
            </div>
          </div>
          <vs-row vs-type="flex">
            <vs-col vs-w="4"></vs-col>
            <vs-col vs-w="4">
              <vs-progress
                :height="10"
                v-if="loading"
                indeterminate
                color="primary"
                >primary</vs-progress
              >
            </vs-col>
            <vs-col vs-w="4"></vs-col>
          </vs-row>
        </div>
      </div>
    </template>
  </vx-card>
  <!-- </div> -->
</template>

<script>
import defBgImage from "../../../assets/images/city/rato.jpeg";
export default {
  data() {
    return {
      auth: {},
      error: false,
      ip: "",
      loading: false,
      backgroundUrl: "",
      parametro: {},
    };
  },
  methods: {
    async login() {
      await this.$vs.loading();
      try {
        const res = await this.$http.post("login", this.auth);
        await this.saveUser(res);
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      } finally {
        this.$vs.loading.close();
      }
    },
    async getIp() {
      fetch("https://api.ipify.org?format=json")
        .then((x) => x.json())
        .then(({ ip }) => {
          this.ip = ip;
        });
    },

    async replaceImg(e) {
      e.target.src = defBgImage;
    },

    async saveUser(res) {
      await window.localStorage.setItem("ip", this.ip);
      await window.localStorage.setItem("token", res.token.token);
      await window.localStorage.setItem("email", res.colaborador.email);
      await window.localStorage.setItem("nome", res.colaborador.nome);
      await window.localStorage.setItem("id", res.colaborador.id);
      await window.localStorage.setItem(
        "imagem_colaborador",
        res.colaborador.foto
      );
      await window.localStorage.setItem(
        "atividades",
        JSON.stringify(res.atividades)
      );
      await this.go();
    },
    async go() {
      window.location.href = await "/home";
    },
    async setImageBackground() {
      await this.$vs.loading();
      try {
        this.parametro = await this.$http.get("getBackground");
        this.backgroundUrl =
          this.parametro.storage_cdn_url +
          "/" +
          this.parametro.imagem_background;
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      } finally {
        this.$vs.loading.close();
      }
    },
  },
  async mounted() {
    await this.setImageBackground();
    await this.getIp();
    document.addEventListener("keydown", (e) => {
      if(e.keyCode == 13){
        this.login()
      }
    });
  },
};
</script>
<style>
</style>
